$(function() {

	toastr.options = {
		closeButton: true,
		progressBar: true,
		positionClass: 'toast-top-right',
		preventDuplicates: true,
	};

	if(typeof mensagem != 'undefined'){

		toastr[tipoMensagem](mensagem);
	}

	$('#botaoBuscarPorNomeMenu').click(function (){

		if($('[name=nomeDespachanteMenu]').val() != "" && $('[name=nomeDespachanteMenu]').val().length >= 4){

			window.location = ($('meta[name="base-url"]').attr('content') + '/buscarPorNome/despachantes/' + $('[name=nomeDespachanteMenu]').val());
		}
		else{

			toastr.error('Informe o nome do despachante pra buscar com pelo menos 4 caracteres!');
		}
	});
});